import { FC, useState } from 'react';
import { useRouter } from '../../modules/router/RouterProvider';
import AddressDetailsReport from '../../components/pdf/Address/AddressDetailsReport';
import Page from '../../components/pdf/Page';
import {
  useAddressGetAddress,
  useAddressGetAddressTagAggregatedIncomingValues,
  useAddressGetAddressTagAggregatedOutgoingValues,
  useAddressGetAttributionTimeStamp,
  useAddressGetMainBalance,
  useAddressGetTokenSummary,
} from '../../api/address';
import { isTokenCurrency } from '../../utils/helpers/currency';
import { useQuery } from 'react-query';
import ErcTokensReport from '../../components/pdf/Address/ErcTokensReport';
import FlowAnalysis from '../../components/pdf/Address/FlowAnalysis';
import CounterpartySummaryReport from '../../components/pdf/Address/CounterpartySummaryReport';
import { useAlertGetAlerts } from '../../api/alert';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';
import AlertsReport from '../../components/pdf/Alerts/AlertsReport';
import { useCommentGetComments } from '../../api/comment';
import CommentsReport from '../../components/pdf/CommentsReport';
import ClassifiersReport from '../../components/pdf/ClassifiersReport';
import { classifierApi } from '../../api/classifier';
import { useStagesListLiteGet } from '../../api/stages';
import { getColorMappingStageOptions } from '../../utils/helpers/stageOptionsColor';
import { IStageOptions } from '../dashboard/settings/Workspace/AssignmentWorkflow';
import StageBadge from '../../ui/components/Badges/StageBadge/StageBadge';

const AddressReport: FC = () => {
  const { getParams, getQueryParams } = useRouter();
  const addressId = Number(getParams().identifier || getParams().addressId);
  const currency = Number(getQueryParams().currency);
  const entities = getQueryParams().entities?.split(',');
  const noEntity = !entities || entities.length === 0;
  const [stagesId, setStagesId] = useState({
    closed: [],
    in_progress: [],
    open: [],
  });
  const shouldShowSection = (entityName: string): boolean => {
    return noEntity || entities.includes(entityName);
  };
  const stageListQuery = useStagesListLiteGet(true, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => {
      const stagesIdObj = data?.data.results.reduce(
        (acc, item) => {
          if (item.category === 2 || item.category === 3) {
            acc.closed.push(item.id.toString());
          }
          if (item.category === 1) {
            acc.in_progress.push(item.id.toString());
          }
          if (item.category === 0) {
            acc.open.push(item.id.toString());
          }
          return acc;
        },
        { closed: [], in_progress: [], open: [] }
      );

      setStagesId(stagesIdObj);
    },
    enabled:
      shouldShowSection('closedAlerts') ||
      shouldShowSection('openAlerts') ||
      shouldShowSection('inProgressAlerts'),
  });
  const stageColorMap = getColorMappingStageOptions(stageListQuery?.data?.data?.results);

  const { data: addressData } = useAddressGetAddress(addressId);
  const { data: tokenSummary } = useAddressGetTokenSummary(addressId, {
    enabled: shouldShowSection('addressDetails') && isTokenCurrency(currency),
  });
  const addressMainBalance = useAddressGetMainBalance(addressId, {
    enabled: shouldShowSection('addressDetails'),
  });
  const addressAttributionTimeStamp = useAddressGetAttributionTimeStamp(addressData?.data?.id, {
    enabled:
      shouldShowSection('addressDetails') &&
      ![undefined, null].includes(addressData?.data?.id) &&
      (addressData?.data?.tag_name_verbose_owner === 'Nobitex.ir' ||
        addressData?.data?.tag_name_verbose === 'Nobitex.ir'),
  });
  const { data: incomingValuesAddress } = useAddressGetAddressTagAggregatedIncomingValues(addressId, {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('counterpartySummary'),
  });
  const { data: outgoingValuesAddress } = useAddressGetAddressTagAggregatedOutgoingValues(Number(addressId), {
    enabled: shouldShowSection('flowAnalysis') || shouldShowSection('counterpartySummary'),
  });
  const { data: openAlertsData } = useAlertGetAlerts(
    { id: addressId, type: 'address', status: [...stagesId.open] },
    {
      enabled: shouldShowSection('openAlerts'),
    }
  );
  const { data: closedAlertsData } = useAlertGetAlerts(
    {
      id: addressId,
      type: 'address',
      status: [...stagesId.closed],
    },
    {
      enabled: shouldShowSection('closedAlerts'),
    }
  );
  const { data: inProgressAlertsData } = useAlertGetAlerts(
    {
      id: addressId,
      type: 'address',
      status: [...stagesId.in_progress],
    },
    {
      enabled: shouldShowSection('inProgressAlerts'),
    }
  );
  const commentsQuery = useCommentGetComments(
    { id: addressId, type: 'address' },
    {
      enabled: shouldShowSection('addressComments'),
    }
  );
  const { data: classifiers } = useQuery(['classifierApi.getClassifiers'], () =>
    classifierApi.getClassifiers({ limit: 100, rule_type: 1 })
  );
  const [, tokenSummaryDataFlatten] = flattenInfiniteQueryResult(tokenSummary);
  const [, openAlerts] = flattenInfiniteQueryResult(openAlertsData);
  const [, closedAlerts] = flattenInfiniteQueryResult(closedAlertsData);
  const [, inProgressAlerts] = flattenInfiniteQueryResult(inProgressAlertsData);
  const [, comments] = flattenInfiniteQueryResult(commentsQuery?.data);
  const isCounterpartyVisible = (): boolean => {
    return (
      (shouldShowSection('counterpartySummary') &&
        incomingValuesAddress?.data?.counter_party_summary?.length &&
        incomingValuesAddress?.data?.counter_party_summary?.length > 0) ||
      (outgoingValuesAddress?.data?.counter_party_summary?.length &&
        outgoingValuesAddress?.data?.counter_party_summary?.length > 0)
    );
  };
  let part = 1;

  const AddressFooter: FC = () => {
    return <div className='text-3xs text-gray-500'>Address Report: {addressData?.data?.identifier}</div>;
  };

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (option.label === undefined) {
      return <StageBadge role={8} label='-NA-' />;
    }
    return <StageBadge role={stageColorMap[option?.id]} label={option?.label} />;
  };

  return (
    <div>
      {shouldShowSection('addressDetails') && (
        <Page footer={<AddressFooter />}>
          {addressData?.data && (
            <AddressDetailsReport
              addressData={addressData?.data}
              balance={{
                balance: addressMainBalance?.data?.data?.balance,
                balance_usd: addressMainBalance?.data?.data?.balance_usd,
              }}
              attribution_timestamp={addressAttributionTimeStamp?.data?.data?.attribution_timestamp}
              part={part++}
            />
          )}
          {tokenSummaryDataFlatten && (
            <ErcTokensReport tokenSummary={tokenSummaryDataFlatten} currency={currency} part={part++} />
          )}
        </Page>
      )}
      {shouldShowSection('flowAnalysis') &&
        incomingValuesAddress?.data?.counter_party_summary?.length &&
        outgoingValuesAddress?.data?.counter_party_summary?.length && (
          <Page footer={<AddressFooter />}>
            <FlowAnalysis
              inputs={incomingValuesAddress?.data?.counter_party_summary}
              outputs={outgoingValuesAddress?.data?.counter_party_summary}
              part={part++}
            />
          </Page>
        )}

      {isCounterpartyVisible() && (
        <Page footer={<AddressFooter />}>
          <CounterpartySummaryReport
            incoming={incomingValuesAddress?.data?.counter_party_summary}
            outgoing={outgoingValuesAddress?.data?.counter_party_summary}
            part={part++}
          />
        </Page>
      )}
      {shouldShowSection('openAlerts') && openAlerts?.length > 0 && (
        <Page footer={<AddressFooter />}>
          <AlertsReport
            type='address'
            alerts={openAlerts}
            title='Open Alerts'
            identifier={addressData?.data?.identifier}
            part={part++}
            getCustomOptionLabel={getCustomOptionLabel}
          />
        </Page>
      )}
      {shouldShowSection('inProgressAlerts') && inProgressAlerts?.length > 0 && (
        <Page footer={<AddressFooter />}>
          <AlertsReport
            type='address'
            alerts={inProgressAlerts}
            title='In Progress Alerts'
            identifier={addressData?.data?.identifier}
            part={part++}
            getCustomOptionLabel={getCustomOptionLabel}
          />
        </Page>
      )}
      {shouldShowSection('closedAlerts') && closedAlerts?.length > 0 && (
        <Page footer={<AddressFooter />}>
          <AlertsReport
            type='address'
            alerts={closedAlerts}
            title='Closed Alerts'
            identifier={addressData?.data?.identifier}
            part={part++}
            getCustomOptionLabel={getCustomOptionLabel}
          />
        </Page>
      )}
      {shouldShowSection('addressComments') && comments?.length > 0 && (
        <Page footer={<AddressFooter />}>
          <CommentsReport
            comments={comments}
            type='address'
            identifier={addressData?.data?.identifier}
            part={part++}
            isError={commentsQuery?.isError || comments?.[0] === undefined}
          />
        </Page>
      )}
      {classifiers?.data.results && (
        <Page footer={<AddressFooter />}>
          <ClassifiersReport classifiers={classifiers?.data.results} type='address' />
        </Page>
      )}
    </div>
  );
};

export default AddressReport;
