import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query';
import { Api } from './api';
import {
  IBillingInvoicesResponse,
  IBillingPlanResponse,
  IBillingDetailsResponse,
  IInvoiceParam,
  IPermissionsResponse,
  ISubscriptionRequest,
  ISubscriptionResponse,
  ICreditUsageRequest,
  ICreditUsageResponse,
  IProfilePlanResponse,
} from './dtos/billing';
import { getNextPageParam } from '../utils/helpers/helperFunctions';

export class BillingApi extends Api {
  readonly baseUrl: string = '/profile/billing/';

  readonly getPlan = () => {
    return this.http.get<IBillingPlanResponse>(this.route('plan/'));
  };

  readonly getDetails = () => {
    return this.http.get<IBillingDetailsResponse>(this.route('details/'));
  };
}

export class SubscriptionApi extends Api {
  readonly baseUrl: string = '/subscriptions/';

  readonly getInvoices = (request: IInvoiceParam) => {
    const { organizationId, subscriptionId, offset = 0, limit = 20 } = request;
    return this.http.get<IBillingInvoicesResponse>(
      this.route(`customer/${organizationId}/subscription/${subscriptionId}/invoice/`),
      {
        params: {
          limit,
          offset,
        },
      }
    );
  };

  readonly getSubscription = (request: ISubscriptionRequest) => {
    const { organizationId } = request;
    return this.http.get<ISubscriptionResponse>(this.route(`customer/${organizationId}/subscription/`));
  };
}

export class PermissionsApi extends Api {
  // TODO: move to store on page initialization
  readonly baseUrl: string = '/auth0/api/permissions/';

  readonly getPermissions = () => {
    return this.http.get<IPermissionsResponse>(this.route(''));
  };
}

export class PlanApi extends Api {
  readonly baseUrl: string = '/profile/';

  readonly getProfilePlan = () => {
    return this.http.get<IProfilePlanResponse>(this.route('plan/'));
  };

  readonly getCreditUsage = (params: ICreditUsageRequest) => {
    const { interval, date_screened, offset, start_date, end_date } = params;
    return this.http.get<ICreditUsageResponse>(this.route('credit-usage/'), {
      params: {
        offset,
        interval,
        date_screened,
        start_date,
        end_date,
      },
    });
  };

  readonly getCreditUsageNonUnique = (params: ICreditUsageRequest) => {
    const { interval, date_screened, offset, start_date, end_date } = params;
    return this.http.get<ICreditUsageResponse>(this.route('credit-usage-nonunique/'), {
      params: {
        offset,
        interval,
        date_screened,
        start_date,
        end_date,
      },
    });
  };
}

export const billingApi = new BillingApi();
export const subscriptionApi = new SubscriptionApi();
export const permissionsApi = new PermissionsApi();
export const planApi = new PlanApi();

export const useBillingPlanGet = (options?: UseQueryOptions) => {
  const key = 'BillingApi.getPlan';
  const result = useQuery(key, billingApi.getPlan, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};

export const useProfilePlanGet = (options?: UseQueryOptions) => {
  const key = 'planApi.getProfilePlan';
  const result = useQuery(key, () => planApi.getProfilePlan(), { ...options } as unknown);

  return {
    key,
    ...result,
  };
};

export const useBillingDetailsGet = (options?: UseQueryOptions) => {
  const key = 'BillingApi.getDetails';
  const result = useQuery(key, billingApi.getDetails, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};

export const useBillingCreditUsageGet = (request: ICreditUsageRequest, options?: UseQueryOptions) => {
  const key = [
    'BillingApi.getCreditUsage',
    request.interval,
    request.date_screened,
    request.start_date,
    request.end_date,
  ];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => planApi.getCreditUsage({ ...request, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return {
    key,
    ...result,
  };
};

export const useBillingCreditNonUniqueUsageGet = (
  request: ICreditUsageRequest,
  options?: UseQueryOptions
) => {
  const key = [
    'BillingApi.getCreditUsageNonUnique',
    request.interval,
    request.date_screened,
    request.start_date,
    request.end_date,
  ];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => planApi.getCreditUsageNonUnique({ ...request, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return {
    key,
    ...result,
  };
};

export const useSubscriptionGet = (request: ISubscriptionRequest, options?: UseQueryOptions) => {
  const key = 'SubscriptionApi.getSubscription';
  const result = useQuery(key, () => subscriptionApi.getSubscription(request), { ...options } as unknown);

  return {
    key,
    ...result,
  };
};

export const usePermissionsGet = (options?: UseQueryOptions) => {
  const key = 'PermissionsApi.getPermissions';
  const result = useQuery(key, permissionsApi.getPermissions, { ...options } as unknown);

  return {
    key,
    ...result,
  };
};
