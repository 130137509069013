import { FC } from 'react';
import { useRouter } from '../../modules/router/RouterProvider';
import Page from '../../components/pdf/Page';
import { useQuery } from 'react-query';
import { classifierApi } from '../../api/classifier';
import { useCommentGetComments } from '../../api/comment';
import ClassifiersReport from '../../components/pdf/ClassifiersReport';
import {
  useCaseGetAddresses,
  useCaseGetCase,
  useCaseGetCustomers,
  useCaseGetTransactions,
} from '../../api/case';
import { flattenInfiniteQueryResult } from '../../utils/helpers/react-query.helper';
import CaseDetailsReport from '../../components/pdf/Case/CaseDetailReport';
import CustomerCaseList from '../../components/pdf/Case/CustomerCaseList';
import AddressCaseList from '../../components/pdf/Case/AddressCaseList';
import TransactionCaseList from '../../components/pdf/Case/TransactionCaseList';
import AssociatedCases from '../../components/AddressTransaction/AssociatedCases';
import CommentsReport from '../../components/pdf/CommentsReport';

const CaseReport: FC = () => {
  const { getParams, getQueryParams } = useRouter();
  const caseId = encodeURIComponent(getParams().identifier || getParams().caseId);
  const entities = getQueryParams().entities?.split(',');
  const noEntity = !entities || entities.length === 0;

  const shouldShowSection = (entityName: string): boolean => {
    return noEntity || entities.includes(entityName);
  };

  const { data: caseData } = useCaseGetCase(Number(caseId), {
    enabled: shouldShowSection('caseDetails'),
  });
  const { data: caseAddresses } = useCaseGetAddresses(Number(caseId), {
    enabled: shouldShowSection('addressesList'),
  });
  const { data: caseTransactions } = useCaseGetTransactions(Number(caseId), {
    enabled: shouldShowSection('transactionsList'),
  });
  const { data: caseCustomers } = useCaseGetCustomers(Number(caseId), {
    enabled: shouldShowSection('customersList'),
  });
  const commentsQuery = useCommentGetComments(
    { id: caseId, type: 'case' },
    {
      enabled: shouldShowSection('comments'),
    }
  );
  const { data: response } = useCaseGetCase(Number(caseId), {
    useErrorBoundary: true,
  });

  let part = 1;

  const { data: classifiers } = useQuery(['classifierApi.getClassifiers'], () =>
    classifierApi.getClassifiers({ limit: 100, rule_type: 1 })
  );

  const [, comments] = flattenInfiniteQueryResult(commentsQuery?.data);
  const [, customerList] = flattenInfiniteQueryResult(caseCustomers);
  const [, addressList] = flattenInfiniteQueryResult(caseAddresses);
  const [, transactionList] = flattenInfiniteQueryResult(caseTransactions);

  const CaseFooter: FC = () => {
    return <div className='text-3xs text-gray-500'>Case Report: {caseData?.data?.id}</div>;
  };

  return (
    <div>
      {shouldShowSection('caseDetails') && caseData?.data && (
        <Page footer={<CaseFooter />}>
          <CaseDetailsReport caseData={caseData?.data} part={part++} />
        </Page>
      )}
      {shouldShowSection('customersList') && customerList?.length > 0 && (
        <Page footer={<CaseFooter />}>
          <CustomerCaseList data={customerList} part={part++} />
        </Page>
      )}
      {shouldShowSection('addressesList') && addressList?.length > 0 && (
        <Page footer={<CaseFooter />}>
          <AddressCaseList data={addressList} part={part++} />
        </Page>
      )}
      {shouldShowSection('transactionsList') && transactionList?.length > 0 && (
        <Page footer={<CaseFooter />}>
          <TransactionCaseList data={transactionList} part={part++} />
        </Page>
      )}
      {shouldShowSection('comments') && comments?.length > 0 && (
        <Page footer={<CaseFooter />}>
          <CommentsReport
            comments={comments}
            type='case'
            identifier={caseId}
            part={part++}
            isError={commentsQuery?.isError || comments?.[0] === undefined}
          />
        </Page>
      )}
      {shouldShowSection('associatedCases') && response?.data.associated_cases.length > 0 && (
        <AssociatedCases associatedCases={response?.data.associated_cases} />
      )}
      {classifiers?.data.results && (
        <Page footer={<CaseFooter />}>
          <ClassifiersReport classifiers={classifiers?.data.results} type='address' />
        </Page>
      )}
    </div>
  );
};

export default CaseReport;
