import { toNumber } from 'lodash';
import { AxiosResponse } from 'axios';
import { InfiniteData, UseInfiniteQueryResult } from 'react-query';
import { IPaginatedResponse, IPaginationMetaResponse } from '../../api/dtos/common';
import { TableProps } from '../../ui/components/Table/Table';

export const getNextPageParamForPagination = (lastPage: AxiosResponse<IPaginatedResponse<unknown>>) => {
  if (lastPage.data.next == null) {
    return undefined;
  }

  const next = lastPage.data.next.match(/page=([\d]+)/);
  const page = toNumber(next[1]);

  if (isNaN(page)) {
    return undefined;
  }

  return page;
};

export const getNextPageParamFromMeta = (lastPage: AxiosResponse<IPaginationMetaResponse<unknown>>) => {
  if (lastPage.data?.meta?.current === lastPage.data?.meta?.total_pages) {
    return undefined;
  }

  return lastPage.data.meta.current + 1;
};

export const getNextPageParamByOffset = (lastPage: AxiosResponse<IPaginatedResponse<unknown>>) => {
  if (lastPage?.data.next == null) {
    return undefined;
  }

  const next = lastPage?.data.next.match(/offset=([\d]+)/);
  const offset = toNumber(next[1]);

  if (isNaN(offset)) {
    return undefined;
  }

  return offset;
};

export const getNewOffsetForSearchPage = (lastPage: AxiosResponse<IPaginatedResponse<unknown>>) => {
  const next = lastPage?.request?.responseURL?.match(/offset=([\d]+)/);
  const offset = toNumber(next[1]);

  if (isNaN(offset)) {
    return undefined;
  }
  return offset + 25;
};

export const getNextPageParamByOffsetTs = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lastPage: AxiosResponse<IPaginatedResponse<any>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allPages: AxiosResponse<IPaginatedResponse<any>>[]
) => {
  const totalCount = allPages.reduce((acc, curr) => acc + curr.data.results.length, 0);
  if (lastPage.data.count === totalCount) {
    return undefined;
  }

  const next = lastPage.data.results.at(-1)?.block_date_time;
  return next;
};

export const flattenInfiniteQueryResult = <T>(
  data: InfiniteData<AxiosResponse<IPaginatedResponse<T>>>
): [number, T[]] => {
  const results =
    data?.pages
      ?.map((d) => d?.data)
      ?.map((d) => d?.results)
      .flat(1) ?? [];

  const count = data?.pages?.map((d) => d?.data).at(-1)?.count;

  return [count, results];
};

export const flattenInfiniteQueryResultMeta = <T>(
  data: InfiniteData<AxiosResponse<IPaginationMetaResponse<T>>>
): [number, T[]] => {
  const results =
    data?.pages
      ?.map((d) => d.data)
      ?.map((d) => d.results)
      .flat(1) ?? [];

  const count = data?.pages?.map((d) => d.data).at(-1)?.meta?.count;

  return [count, results];
};

export const isInfiniteQueryLoadingForTable = (queryResult: UseInfiniteQueryResult) => {
  return queryResult.isLoading || (queryResult.isFetching && !queryResult.isFetchingNextPage) || false;
};

export const buildInfiniteQueryTableProps = (queryResult: UseInfiniteQueryResult): Partial<TableProps> => {
  return {
    hasNextPage: !!queryResult.hasNextPage,
    fetchNextPage: queryResult.fetchNextPage,
    isFetchingNextPage: queryResult.isFetchingNextPage,
    isLoading: queryResult.isLoading,
    isError: queryResult.isError,
  };
};
