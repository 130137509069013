import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';
import { CaretDown, Circle } from '@phosphor-icons/react';
import { ITabMenuType } from './types';
import CustomLink from '../../ui/components/CustomLink';
import { useRouter } from '../../../modules/router/RouterProvider';
import { calculateVh } from '../../../utils/helpers/common';

interface TabsProps {
  tabs: ITabMenuType[];
  type: string;
}

const SettingsTabs: FC<TabsProps> = ({ tabs, type }) => {
  const { pathname } = useRouter();
  const [showSubTabs, setShowTabs] = useState<{ [key: string]: boolean }>({});

  const initializeSubTabs = () => {
    const initialSubTabs = {};
    tabs.forEach((mainTab) => {
      mainTab.tabs.forEach((subTab) => {
        if (subTab.tabs?.length === 1) {
          initialSubTabs[subTab.name] = true;
        } else {
          initialSubTabs[subTab.name] = subTab.tabs?.some((innerTab) => pathname.includes(innerTab.name));
        }
      });
    });
    return initialSubTabs;
  };

  useEffect(() => {
    setShowTabs(initializeSubTabs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex h-full'>
      <div className='ml-[256px]'>
        <div
          className='fixed left-0 w-64 min-w-[256px] flex-none overflow-y-auto overflow-x-hidden border-r pt-2'
          style={{ height: calculateVh(62) }}>
          {tabs.map((tab, i) => (
            <div key={i}>
              <div className='p-3 pl-4 text-xs font-medium uppercase text-gray-500'>{tab.type}</div>
              <div className='mx-2'>
                <ul className='flex flex-col'>
                  {tab.tabs.map((m, j) => (
                    <li key={`${i}-${j}`}>
                      <CustomLink
                        to={
                          !m.tabs?.length
                            ? `/settings/${type}/${m.name}`
                            : `/settings/${type}/${m.tabs[0]?.name}`
                        }
                        onClickHandle={() => {
                          if (m?.tabs?.length > 0) {
                            setShowTabs((prev) => ({
                              ...prev,
                              [m.name]: !prev[m.name],
                            }));
                          }
                        }}
                        className={({ isActive }) =>
                          classNames(
                            'group mb-2 flex items-center justify-between rounded-md px-3 py-2 text-sm',
                            {
                              'bg-blue-100 text-blue-600': isActive && !m.tabs,
                              'text-gray-800 hover:bg-blue-50 hover:text-blue-600': !isActive,
                            }
                          )
                        }>
                        {m.label}
                        {m.tabs && <CaretDown className='my-auto' size={16} />}
                      </CustomLink>
                      {showSubTabs[m.name] && m.tabs?.length > 0 && (
                        <ul>
                          {m.tabs.map((s, k) => (
                            <li key={`${i}-${j}-${k}`}>
                              <CustomLink
                                to={`/settings/${type}/${s.name}`}
                                className={({ isActive }) =>
                                  classNames(
                                    'group mb-2 ml-3 flex items-center rounded-md px-3 py-2 text-sm',
                                    {
                                      'bg-blue-100 text-blue-600': isActive,
                                      'text-gray-800 hover:bg-blue-50 hover:text-blue-600': !isActive,
                                    }
                                  )
                                }>
                                <Circle weight='fill' size={6} className='mr-2' />
                                {s.label}
                              </CustomLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              {i < tabs.length - 1 && <hr className='m-2' />}
            </div>
          ))}
        </div>
      </div>
      <div className='flex-1 overflow-auto p-4 px-6'>
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsTabs;
