import { FC, useEffect, useState } from 'react';
import Tab from '../../ui/components/Tab/Tab';
import { alertApi, useAlertGetAlerts } from '../../../api/alert';
import { flattenInfiniteQueryResult } from '../../../utils/helpers/react-query.helper';
import Pagination from '../../../containers/Pagination';
import Alert from './Alert/Alert';
import AlertFilter, { defaultStateFilters } from './Alert/AlertFilter';
import AlertAppliedFilters from './Alert/AlertAppliedFilters';
import { UseInfiniteQueryResult, useMutation, useQueryClient } from 'react-query';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { IAlertFiltersUpdate, IAlertGetAlertsResponse } from '../../../api/dtos/alert';
import { every, isEmpty } from 'lodash';
import { AxiosError, AxiosResponse } from 'axios';
import Skeleton from 'react-loading-skeleton';
import UpdateStageorAssigneeModal from '../UpdateStageorAssigneeModal';
import { useWorkSpaceGetWorkFlowMembers } from '../../../api/workspaces';
import { useDebounce } from 'use-debounce';
import { IWorkSpaceMembers } from '../../../api/dtos/workspaces';
import StageBadge from '../../../ui/components/Badges/StageBadge/StageBadge';
import { useStagesListLiteGet } from '../../../api/stages';
import { getColorMappingStageOptions } from '../../../utils/helpers/stageOptionsColor';
import UserBadge from '../../../ui/components/Badges/UserBadge/UserBadge';
import { IOption } from '../../../components/ui/components/Select/SingleSelect';
import { IStageOptions } from './Alert/AlertButtonPanel/AlertButtonPanel';
import { useAuth } from '../../../modules/auth';
import { getErrorMessage } from '../../../utils/helpers/helperFunctions';

interface AlertsProps {
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
  // custId is numeric id of customer used for resolving all alerts
  custId?: number;
  setAlerts?: (alerts: number) => void;
  setAlertDetails?: (alert: number) => void;
  entity_type?: string;
  showLoading?: boolean;
  showFullHeight?: boolean;
  noDataAfterLoad?: () => void;
}

const Alerts: FC<AlertsProps> = ({
  type,
  id,
  setAlerts,
  setAlertDetails,
  entity_type,
  showLoading = false,
  showFullHeight = false,
  noDataAfterLoad,
}) => {
  const { state } = useAuth();
  const [tab, setTab] = useState<number>(0);
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState<IAlertFiltersUpdate>(defaultStateFilters);
  const [isBulkUpdate, setIsBulkUpdate] = useState<boolean>(false);
  const [selectedOptionId, setSelectedOptionId] = useState<number>();
  const [openModal, setOpenModal] = useState('');
  const [selectedOption, setSelectedOption] = useState<IStageOptions>();
  const [currentOption, setCurrentOption] = useState<IStageOptions>();
  const [selectedAlertId, setSelectedAlertId] = useState<number | string>();
  const [isAllAlertsSelected, setIsAllAlertsSelected] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState<number[]>([]);
  const [nextStageOptions, setNextStageOptions] = useState<IStageOptions[]>();
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 500);
  const [comment, setComment] = useState('');
  const [stagesId, setStagesId] = useState({
    closed: [],
    open: [],
    in_progress: [],
  });
  const openAlertsQuery = useAlertGetAlerts(
    {
      id,
      type,
      status: [...stagesId.open],
      filters: filters,
      entity_type,
      closed: true,
    },
    {
      enabled: stagesId.open.length !== 0 && filters.status.length === 0,
    }
  );
  const assignedAlertsQuery = useAlertGetAlerts(
    {
      id,
      type,
      entity_type,
      assigned_to: state.userProfile?.id,
      filters: filters,
      assigned: true,
    },
    {
      enabled: filters.assigned_to.length === 0,
    }
  );
  const unAssignedAlertsQuery = useAlertGetAlerts(
    {
      id,
      type,
      filters: filters,
      entity_type,
      assigned_to: 'None',
      assigned: true,
    },
    {
      enabled: filters.assigned_to.length === 0,
    }
  );
  const closedAlertsQuery = useAlertGetAlerts(
    {
      id,
      type,
      filters: filters,
      status: [...stagesId.closed],
      entity_type,
      closed: true,
    },
    {
      enabled: stagesId.closed.length !== 0 && filters.status.length === 0,
    }
  );
  const inProgressAlertsQuery = useAlertGetAlerts(
    {
      id,
      type,
      status: [...stagesId.in_progress],
      filters: filters,
      entity_type,
      closed: true,
    },
    {
      enabled: stagesId.in_progress.length !== 0 && filters.status.length === 0,
    }
  );
  const allAlertsQuery = useAlertGetAlerts({
    id,
    type,
    filters: filters,
    entity_type,
  });
  const assignedToOptionsQuery = useWorkSpaceGetWorkFlowMembers({
    q: debouncedSearch,
    is_active: true,
    id: 1,
  });
  const [, assignedToOptions]: [number, IWorkSpaceMembers[]] = flattenInfiniteQueryResult(
    assignedToOptionsQuery?.data
  );

  const stagesListLiteQuery = useStagesListLiteGet(true, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => {
      const stagesIdObj = data?.data.results.reduce(
        (acc, item) => {
          if (item?.category === 2 || item?.category === 3) {
            acc.closed.push(item.id.toString());
          }
          if (item?.category === 0) {
            acc.open.push(item.id.toString());
          }
          if (item?.category === 1) {
            acc.in_progress.push(item.id.toString());
          }
          return acc;
        },
        { closed: [], open: [], in_progress: [] }
      );

      setStagesId(stagesIdObj);
    },
  });
  const { mutate: bulkAlertStatusUpdate, isLoading: isLoadingUpdateStageorAssignee } = useMutation(
    alertApi.updateMultipleAlerts,
    {
      onSuccess: () => {
        setSearch('');
        setSelectedAlerts([]);
        setIsAllAlertsSelected(false);
        alertsQuery.refetch();
        const key = ['alertApi.getAlerts', id];
        queryClient.invalidateQueries(key);
        toast.success('Alerts Updated Successfully');
        setOpenModal('');
        setComment('');
        setSelectedOption(null);
        setSelectedOptionId(null);
        setCurrentOption(null);
      },
      onError(err: AxiosError<{ error: string }>) {
        toast.error(getErrorMessage(err));
      },
    }
  );
  const { mutate: mutateAlert } = useMutation(alertApi.updateAlert, {
    onSuccess: () => {
      setSelectedAlerts([]);
      setIsAllAlertsSelected(false);
      alertsQuery.refetch();
      const key = ['alertApi.getAlerts', id];
      queryClient.invalidateQueries(key);
      toast.success('Alerts Updated Successfully');
      setOpenModal('');
      setComment('');
      setSelectedOption(null);
      setSelectedOptionId(null);
      setCurrentOption(null);
    },
    onError(err: AxiosError<{ error: string }>) {
      toast.error(getErrorMessage(err));
    },
  });
  const getStageOptions = (stageData) => {
    return stageData
      ?.filter((r) => r.category !== 3)
      .map((r) => ({
        id: r.id,
        value: r.name,
        label: r.name,
      }));
  };

  const getAssignedToOptions = (assignedToData: IWorkSpaceMembers[]) => {
    return assignedToData?.map((r) => {
      return {
        value: r.email,
        label: r.email,
      };
    });
  };

  const stageOptionsData = getStageOptions(stagesListLiteQuery?.data?.data?.results);
  const assignedToOptionsData = getAssignedToOptions(assignedToOptions);

  const [openAlertsCount, openAlertsData] = flattenInfiniteQueryResult(openAlertsQuery.data);
  const [assignedAlertsCount, assignedAlertsData] = flattenInfiniteQueryResult(assignedAlertsQuery.data);
  const [unAssignedAlertsCount, unAssignedAlertsData] = flattenInfiniteQueryResult(
    unAssignedAlertsQuery.data
  );
  const [closedAlertsCount, closedAlertsData] = flattenInfiniteQueryResult(closedAlertsQuery.data);
  const [inProgressAlertsCount, inProgressAlertsData] = flattenInfiniteQueryResult(
    inProgressAlertsQuery.data
  );
  const [allAlertsCount, allAlertsData] = flattenInfiniteQueryResult(allAlertsQuery.data);
  const stageColorMap = getColorMappingStageOptions(stagesListLiteQuery?.data?.data?.results);

  const isLoading =
    openAlertsQuery.isLoading ||
    assignedAlertsQuery.isLoading ||
    unAssignedAlertsQuery.isLoading ||
    closedAlertsQuery.isLoading ||
    inProgressAlertsQuery.isLoading ||
    allAlertsQuery.isLoading;

  const onApply = (filters) => {
    setFilters(filters);
  };

  useEffect(() => {
    setAlerts(openAlertsCount);
  }, [openAlertsCount, setAlerts]);

  useEffect(() => {
    if (
      !isLoading &&
      openAlertsCount === 0 &&
      assignedAlertsCount === 0 &&
      unAssignedAlertsCount === 0 &&
      closedAlertsCount === 0 &&
      inProgressAlertsCount === 0 &&
      allAlertsCount === 0
    ) {
      noDataAfterLoad?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    openAlertsCount,
    assignedAlertsCount,
    unAssignedAlertsCount,
    closedAlertsCount,
    inProgressAlertsCount,
    allAlertsCount,
  ]);
  const isFilterApplied = !every(filters, (f) => isEmpty(f));
  const tabs = [
    {
      label: 'Assigned to me',
      count: assignedAlertsCount,
      hidden: filters.assigned_to.length === 0 ? !assignedAlertsCount : true,
    },
    { label: 'Open', count: openAlertsCount, hidden: filters.status.length === 0 ? !openAlertsCount : true },
    {
      label: 'In Progress',
      count: inProgressAlertsCount,
      hidden: filters.status.length === 0 ? !inProgressAlertsCount : true,
    },
    {
      label: 'Closed',
      count: closedAlertsCount,
      hidden: filters.status.length === 0 ? !closedAlertsCount : true,
    },
    {
      label: 'Unassigned',
      count: unAssignedAlertsCount,
      hidden: filters.assigned_to.length === 0 ? !unAssignedAlertsCount : true,
    },
    {
      label: 'All',
      count: allAlertsCount,
    },
  ];

  // const alertsCount = [
  //   assignedAlertsCount,
  //   openAlertsCount,
  //   inProgressAlertsCount,
  //   closedAlertsCount,
  //   unAssignedAlertsCount,
  //   allAlertsCount,
  // ][tab];
  const alertsData = [
    assignedAlertsData,
    openAlertsData,
    inProgressAlertsData,
    closedAlertsData,
    unAssignedAlertsData,
    allAlertsData,
  ][tab];
  const alertsQuery = [
    assignedAlertsQuery,
    openAlertsQuery,
    inProgressAlertsQuery,
    closedAlertsQuery,
    unAssignedAlertsQuery,
    allAlertsQuery,
  ][tab];

  const onChange = (tab: number) => {
    setTab(tab);
    setSelectedAlerts([]);
    setIsAllAlertsSelected(false);
  };

  const handleChangeStage = (stage: IOption) => {
    setSelectedOption(stage);
    const foundStage = stageOptionsData.find((r) => r.label === stage?.label);
    setSelectedOptionId(foundStage?.id);
  };
  const handleChangeAssignee = (assignee: IOption) => {
    setSelectedOption(assignee);
    const foundAssignee = assignedToOptions.find((r) => r.email === assignee?.label);
    setSelectedOptionId(foundAssignee?.user_id);
  };

  const selectAlerts = (id) => {
    if (selectedAlerts?.includes(id)) {
      setSelectedAlerts(selectedAlerts.filter((i) => i !== id));
    } else {
      setSelectedAlerts([...selectedAlerts, id]);
    }
  };

  const selectAllAlerts = (force = false) => {
    if (force) {
      setSelectedAlerts(alertsData.map((alert) => alert.id));
    } else {
      if (selectedAlerts.length === alertsData.length) {
        setSelectedAlerts([]);
        setIsAllAlertsSelected(false);
      } else {
        setSelectedAlerts(alertsData.map((alert) => alert.id));
      }
    }
  };

  const handleSubmit = () => {
    if (comment) {
      if (isBulkUpdate) {
        const updatedFilters = { ...filters };
        switch (tab) {
          case 0:
            updatedFilters.assigned_to = [state.userProfile?.id];
            break;
          case 1:
            updatedFilters.status = [...stagesId.open];
            break;
          case 2:
            updatedFilters.status = [...stagesId.in_progress];
            break;
          case 3:
            updatedFilters.status = [...stagesId.closed];
            break;
          case 4:
            updatedFilters.assigned_to = ['None'];
            break;

          default:
            break;
        }
        bulkAlertStatusUpdate({
          comment: comment,
          ids: selectedAlerts,
          filters: undefined,
          updated_status: openModal === 'status' ? selectedOptionId : undefined,
          updated_assigned_to: openModal === 'assignee' ? selectedOptionId : undefined,
        });
      } else {
        mutateAlert({
          type,
          id,
          status: openModal === 'status' ? selectedOptionId : undefined,
          assigned_to: openModal === 'assignee' ? selectedOptionId : undefined,
          alertId: selectedAlertId,
          comment: comment,
        });
      }
    } else {
      toast.error('Enter a comment');
    }
  };

  const isNoAlerts =
    !openAlertsCount &&
    !assignedAlertsCount &&
    !unAssignedAlertsCount &&
    !closedAlertsCount &&
    !inProgressAlertsCount &&
    !allAlertsCount &&
    !isLoading &&
    !isFilterApplied;

  const renderPagination = (query: UseInfiniteQueryResult<AxiosResponse<IAlertGetAlertsResponse>>) => {
    if (showLoading ? query.isLoading : false)
      return <Skeleton count={4} height={55} className='' containerClassName='mt-3 flex flex-col' />;
    return (
      <Pagination
        query={query}
        className={`${showFullHeight ? 'max-h-[70vh]' : 'max-h-96'} text-sm`}
        errorMessage='No alerts available'>
        {(alert) => (
          <Alert
            alert={alert}
            id={id}
            key={alert.id}
            type={type}
            setAlertDetails={setAlertDetails}
            selectAlerts={selectAlerts}
            selectedAlerts={selectedAlerts}
            setIsBulkUpdate={setIsBulkUpdate}
            setOpenModal={setOpenModal}
            setCurrentOption={setCurrentOption}
            setSelectedAlertId={setSelectedAlertId}
            getCustomOptionLabel={getCustomOptionLabel}
            getCustomLabelAssignee={getCustomLabelAssignee}
            setNextStageOptions={setNextStageOptions}
          />
        )}
      </Pagination>
    );
  };

  const getCustomOptionLabel = (option: IStageOptions) => {
    if (option.label === undefined) {
      return <StageBadge role={8} label='-NA-' />;
    }
    return <StageBadge role={stageColorMap[option?.id]} label={option?.label} />;
  };

  const getCustomLabelAssignee = (option: IOption) => {
    return (
      <UserBadge className='bg-gray-200 text-gray-800' label={option?.label ? option?.label : 'Unassigned'} />
    );
  };

  return (
    <div className='relative'>
      <div
        className={classNames('rounded-md border border-gray-200', {
          // 'pointer-events-none opacity-50': isBulkUpdate,
        })}>
        <Tab
          values={tabs}
          type='primary'
          listClassName='border-b-2 p-4 rounded-t-md'
          inactiveClassName='text-gray-800/30 border-gray-200 cursor-not-allowed'
          panelClassName='px-4'
          headerActions={
            !isNoAlerts && (
              <div className='flex'>
                <AlertFilter
                  filters={filters}
                  onApply={onApply}
                  type={type}
                  disabled={selectedAlerts?.length > 0}
                />
              </div>
            )
          }
          isLoading={showLoading ? undefined : isLoading}
          subHeader={
            <AlertAppliedFilters
              count={alertsData?.length}
              filters={filters}
              selectedAlerts={selectedAlerts}
              isAllAlertsSelected={isAllAlertsSelected}
              selectedText={`${selectedAlerts.length} ${selectedAlerts.length > 1 ? 'alerts' : 'alert'} selected.`}
              setFilters={onApply}
              selectAllAlerts={selectAllAlerts}
              setSelectedAlerts={setSelectedAlerts}
              setIsAllAlertsSelected={setIsAllAlertsSelected}
              setOpenModal={setOpenModal}
              setIsBulkUpdate={setIsBulkUpdate}
              stageOptions={(() => {
                if (stageOptionsData?.length > 0) {
                  const options = [...stageOptionsData];
                  const inactiveStage = stagesListLiteQuery?.data?.data?.results?.find(
                    (item) => item.name === 'Inactive'
                  );
                  if (inactiveStage) {
                    options.push({
                      id: inactiveStage.id,
                      value: inactiveStage.name,
                      label: inactiveStage.name,
                    });
                  }
                  return options;
                }
                return [];
              })()}
            />
          }
          onChange={onChange}>
          {renderPagination(assignedAlertsQuery)}
          {renderPagination(openAlertsQuery)}
          {renderPagination(inProgressAlertsQuery)}
          {renderPagination(closedAlertsQuery)}
          {renderPagination(unAssignedAlertsQuery)}
          {renderPagination(allAlertsQuery)}
        </Tab>
      </div>
      {/* {isBulkUpdate && (
        <div className='absolute inset-x-0 top-1/2 mx-auto flex w-80 text-center text-sm opacity-100'>
          Bulk resolve alerts will complete in few seconds
        </div>
      )} */}
      <UpdateStageorAssigneeModal
        bulkAction={isBulkUpdate}
        open={openModal !== ''}
        setOpen={setOpenModal}
        optionsData={
          openModal === 'status'
            ? isBulkUpdate
              ? stageOptionsData
              : nextStageOptions
            : assignedToOptionsData
        }
        currentOption={currentOption}
        selectedOption={selectedOption}
        setSelectedOption={openModal === 'status' ? handleChangeStage : handleChangeAssignee}
        handleSubmit={handleSubmit}
        setComment={setComment}
        isStageorAssignee={openModal === 'status'}
        comment={comment}
        getCustomOptionLabel={openModal === 'status' ? getCustomOptionLabel : getCustomLabelAssignee}
        setSearch={setSearch}
        isLoading={isLoadingUpdateStageorAssignee}
        assignedToOptionsQuery={assignedToOptionsQuery}
      />
    </div>
  );
};

export default Alerts;
