import { FC, useState } from 'react';
import { IOption } from './CustomSelect';
import Select, { components, DropdownIndicatorProps, StylesConfig } from 'react-select';
import { BasicBadge, Button } from '../../ui';
import { X } from '@phosphor-icons/react';

interface EntityNameFilterProps {
  value: string[];
  setValue: (entityNames: string[]) => void;
}

const EntityNameFilter: FC<EntityNameFilterProps> = (props) => {
  const { value, setValue } = props;
  const [entityNames, setEntityNames] = useState<IOption[]>(
    value
      ? value.map((item) => {
          return {
            label: item,
            value: item,
          };
        })
      : []
  );
  const [inputValue, setInputValue] = useState('');

  const styles: StylesConfig<unknown, false> = {
    control: (css) => ({
      ...css,
      borderRadius: '6px',
      border: '1px solid #D1D5DB',
      minHeight: '28px',
      '*': {
        boxShadow: 'none !important',
      },
      '& > div:first-of-type': {
        overflow: 'visible !important',
      },
    }),
    indicatorsContainer: (css) => ({ ...css, display: 'visible' }),
    input: (css) => ({
      ...css,
      color: 'blue',
      fontSize: '11px',
      paddingTop: '2px',
      cursor: 'text',
      display: 'flex',
    }),
    placeholder: (css) => ({ ...css, fontSize: '15px' }),
    menu: (css) => ({
      ...css,
      border: '1px solid #D1D5DB',
      borderRadius: '6px',
      padding: '2px 0px 2px 0px',
      boxShadow: 'none',
      zIndex: 2,
    }),
    option: (css, { isSelected }) => ({
      ...css,
      padding: '5px',
      backgroundColor: isSelected ? '#DBEAFE' : 'white',
      color: isSelected ? 'white' : 'black',
      cursor: 'pointer',
      border: 0,
      outline: 'none',
      ':hover': {
        color: 'black',
        backgroundColor: '#d6d2d2',
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      background: 'none',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#1E40AF',
      backgroundColor: '#DBEAFE',
      borderTopLeftRadius: 15,
      borderBottomLeftRadius: 15,
      borderRadius: 0,
      fontSize: 12,
      padding: '1px',
      paddingRight: '5px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: '1E40AF',
      backgroundColor: '#DBEAFE',
      paddingLeft: '0px',
      marginLeft: -1,
      borderRadius: 0,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      ':hover': {
        backgroundColor: '#606d7c',
      },
    }),
  };

  const getLabel = (option: IOption) => {
    return <BasicBadge className='bg-gray-100 text-2xs'>{option.label}</BasicBadge>;
  };

  const handleChange = (entityNames: IOption[]) => {
    setEntityNames(entityNames);
    const entityNameValues = entityNames.map((entityName) => entityName.value);
    setValue(entityNameValues);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      if (!entityNames.some((option) => option.value === inputValue)) {
        const oldItem = [...entityNames];
        oldItem.push({ value: inputValue, label: inputValue });
        handleChange(oldItem);
        setInputValue('');
      }
    }
  };

  const CustomMultiValue = (props) => {
    const handleRemove = () => {
      const updatedEntityNames = entityNames.filter((entityName) => entityName.value !== props.data.value);
      handleChange(updatedEntityNames);
    };
    return (
      <BasicBadge className='mr-1 flex gap-2 bg-blue-100 text-blue-800'>
        <div className='text-xs text-gray-800'>{props.data.label}</div>
        <X size={15} className='cursor-pointer' onClick={handleRemove} />
      </BasicBadge>
    );
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<IOption, true>) => {
    return (
      <components.DropdownIndicator {...props} className='!px-2 !py-1'>
        <Button variant='secondary' className='py-1' onClick={() => handleKeyDown({ key: 'Enter' })}>
          Add
        </Button>
      </components.DropdownIndicator>
    );
  };

  return (
    <div>
      <label className='text-sm font-medium text-gray-500'>Entity name</label>
      <Select
        value={entityNames}
        isMulti
        options={[]}
        components={{ MultiValue: CustomMultiValue, DropdownIndicator }}
        isClearable={false}
        getCustomLabel={getLabel}
        getCustomOptionLabel={getLabel}
        hideSelectedOptions={false}
        menuIsOpen={false}
        styles={styles}
        placeholder='Enter entity names'
        noOptionsMessage={() => 'List is empty'}
        onChange={(values) => handleChange(values as IOption[])}
        onKeyDown={(e) => handleKeyDown(e)}
        inputValue={inputValue}
        onInputChange={setInputValue}
      />
    </div>
  );
};

export default EntityNameFilter;
